<template>
  <button :class="$style['reward-popup-submit-button']">
    <slot />
  </button>
</template>

<style lang="scss" module>
.reward-popup-submit-button {
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  color: var(--general-white);
  background: linear-gradient(264deg, #8adcff -4.08%, #132581 116.94%);

  &:hover {
    background: linear-gradient(264deg, #61add2 -4.08%, #0b1a69 116.94%);
  }

  &:active {
    background: linear-gradient(264deg, #61add2 0%, #0b1a69 100%);
  }
}
</style>
